
@tailwind base;
@tailwind components;
@tailwind utilities;

.dash {
    border:"1px dashed orange"

}

.bannerHeight {
    height:"380px"
}
.image-invert {
    filter: invert(100%)
}
.owl-carousel .owl-item img {
    width: -webkit-fill-available;
}

.bg-red-trans {
    background: linear-gradient(90deg,#f7efef 0,rgba(246,135,179,1) 77%,rgba(246,135,179,1));
}
.bg-blue-trans {
    background: linear-gradient(90deg,rgba(232,243,247,1) 0%, rgba(41,184,229,1) 69%, rgba(109,183,237,1));
}
.bg-green-trans {
    background: linear-gradient(90deg, rgba(235,245,238,1) 0%, rgba(104,211,145,1) 77%, rgba(104,211,145,1) 100%)
}
.bg-pink-trans {
    background: linear-gradient(90deg, rgba(245,235,241,1) 0%, rgba(246,135,179,1) 77%, rgba(246,135,179,1))
}
.bg-indego-trans {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 0%, rgba(117,51,117,1))
}
.bg-darkblue-trans {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 0%, rgba(48,72,112,1) 100%)
}